import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, Link, withPrefix } from "gatsby"
import Tag from "./../components/Tag"
import Layout from "./../components/layout/Main"
import { HTMLContent } from "./../components/Content"
import {
  Wrapper,
  WrapperHeader,
  WrapperMainHeading,
} from "./../components/styled/Wrapper"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

const CategoryLink = styled(Link)`
  font-family: "Dancing Script", cursive;
  font-size: 32px;
  color: #00ada7;
  margin-top: 10px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const TagHeader = styled.h4`
  margin-bottom: 10px;
`

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  category,
  categories,
}) => {
  const PostContent = contentComponent
  const postCategoryArr =
    categories &&
    categories.filter(({ node }) => node.frontmatter.slug === category)

  const postCategory =
    postCategoryArr && postCategoryArr[0]
      ? postCategoryArr[0].node.frontmatter
      : null

  return (
    <Wrapper>
      <WrapperHeader>
        <WrapperMainHeading>{title}</WrapperMainHeading>
        {postCategory && (
          <CategoryLink to={`/kategorije/${postCategory.slug}`}>
            #{postCategory.title}
          </CategoryLink>
        )}
      </WrapperHeader>

      <PostContent content={content} />

      <div>
        <CategoryLink to={`/`}>Nazad na početnu stranicu</CategoryLink>
      </div>

      {tags && tags.length ? (
        <div style={{ marginTop: `4rem` }}>
          <TagHeader>Oznake</TagHeader>

          <ul className="taglist">
            {tags.map(
              (tag, i) =>
                tag !== "" && (
                  <Tag key={i} to={`/oznake/${tag}/`}>
                    {tag}
                  </Tag>
                )
            )}
          </ul>
        </div>
      ) : null}
    </Wrapper>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const {
    markdownRemark: post,
    allMarkdownRemark: { edges: categoriesEdges },
  } = data

  return (
    <Layout
      backgroundImg={
        post.frontmatter.cover_image.childImageSharp.gatsbyImageData
      }
    >
      <Helmet titleTemplate="%s | Post">
        <title>{`${post.frontmatter.title}`}</title>
        <meta name="description" content={`${post.frontmatter.description}`} />
        <link
          rel="canonical"
          href={`https://dafnastyle.com${post.fields.slug}`}
        />

        <meta name="author" content="Miljana Justinovic" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta
          property="og:description"
          content={post.frontmatter.description}
        />
        <meta
          property="og:url"
          content={`https://dafnastyle.com${post.fields.slug}`}
        />
        <meta property="og:site_name" content={post.frontmatter.title} />
        <meta
          property="og:image"
          content={`${post.frontmatter.cover_image.childImageSharp.gatsbyImageData.images.fallback.src}`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content={post.frontmatter.description}
        />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta
          name="twitter:image"
          content={`${post.frontmatter.cover_image.childImageSharp.gatsbyImageData.images.fallback.src}`}
        />
      </Helmet>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        categories={categoriesEdges}
        {...post.frontmatter}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "categories-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        category
        thumbnail_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        cover_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
